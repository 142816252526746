import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Main from "./pages/main";
import Impressum from "./pages/impressum";
import GDPR from "./pages/gdpr";
import "./App.scss";
import { AppFooter } from "./Components/AppFooter";
import { AppHeader } from "./Components/AppHeader";

const App = () => {
  return (
    <Router>
      <AppHeader />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/impressum" element={<Impressum />} />
        <Route path="/gdpr" element={<GDPR />} />
      </Routes>
      <AppFooter />
    </Router>
  );
};

export default App;
