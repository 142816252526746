import { useState } from "react";
import { useTranslation } from "react-i18next";
import { localStorageGet, localStorageSet } from "../utils/LocalStorageHelper";
import { changeLanguage } from "i18next";

export const ChangeLanguage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { t } = useTranslation();

  const languages = ["de", "en"];
  const currentLanguage = localStorageGet("i18nextLng");

  return (
    <>
      <div className="dropdown">
        <div
          className="dropdown-button clickable"
          onClick={() => setIsOpen(!isOpen)}
        >
          {t(currentLanguage || "")}
        </div>
        {isOpen && (
          <div className="dropdown-menu">
            {languages.map((e) => (
              <div
                key={e}
                className="dropdown-item"
                onClick={() => {
                  changeLanguage(e);
                  localStorageSet("i18nextLng", e);
                  setIsOpen(false);
                }}
              >
                {t(e)}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};
