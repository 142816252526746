import React from "react";

export const AppFooter = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div>
          <a href="mailto:info@predsense.ai" className="footer-email">
            info@predsense.ai
          </a>
        </div>
        <div className="footer-right">
          <a href="/impressum" className="footer-link">
            Impressum
          </a>
          <a href="/gdpr" className="footer-link">
            Datenschutz
          </a>
        </div>
      </div>
    </footer>
  );
};
